<template>
  <div class="page-404 content">
	<div class="container">
		<h1 class="title">Ой, а тут нічого крім папороті не росте..</h1>
		<p>Краще поверніться на головну стрінку і знайдіть щось цікавіше)</p>
		<div> 
			<router-link to="/" class="btn">Головна</router-link>
		</div>
	</div>
    
  </div>
</template>
<style scoped lang="scss">
	@import "../assets/scss/components/404.scss" 
</style>